<template>

<div>
   <div class="row justify-content-lg-center">
     <div class="cols-12 col-sm-12 col-lg-10 col-md-12 ">
            <!--begin::Dashboard-->
              <div class="row ">
                <div class="col-xl-8">
                  <!-- <ListWidget1></ListWidget1> -->
                </div>
              <div class="col-xl-4">
              </div>
            </div>

            
            <div class="row py-5">
              <div class="col-xl-8 col-lg-8 ">
                <FormCampaignDetail></FormCampaignDetail>
              </div>
              <div class="col-xl-4 col-lg-4 ">
                <!-- <FormCampaignDetail></FormCampaignDetail> -->
              </div>
            </div>


             <CouponStat></CouponStat>




            <div class="row py-5">
                <div class="topbar d-flex justify-end">
                </div>
              <div class="col-xl-12 col-lg-12 ">
                <AdminCouponListTable></AdminCouponListTable>
              </div>
            </div>

    </div>
    </div>
    
  </div>

</template>

<script>
import AdminCouponListTable from "@/view/alex/eCouponAdminCouponListTable.vue";
import FormCampaignDetail from "@/view/alex/eCouponCampaignForm.vue";
import CouponStat from "@/view/alex/eCouponCouponStat.vue";

// import ListWidget1 from "@/view/alex/eCouponMenuList.vue";

export default {
  name: "Branding1",
  data() {
    return {  
   }
  },

  created() {
    // this.tableDetail.data = this.items;

  },
  computed: {
    // ...mapGetters(["items"])
  },

  components: {
    // KTQuickUserx,
    AdminCouponListTable,
    FormCampaignDetail,
    CouponStat
    
    // ListWidget1
  },
  methods: {
    // setCheck(checked) {
    //   this.checked = checked;
    // }
  }
};
</script>



<style lang="scss">
// .topbar {
//   .dropdown-toggle {
//     padding: 0;
//     &:hover {
//       text-decoration: none;
//     }

//     &.dropdown-toggle-no-caret {
//       &:after {
//         content: none;
//       }
//     }
//   }

//   .dropdown-menu {
//     margin: 0;
//     padding: 0;
//     outline: none;
//     .b-dropdown-text {
//       padding: 0;
//     }
//   }
// }
</style>