<template>

<div>
   

            <!-- ROW -->
            <div class=" row py-5" style="font-family:mitr;">
                  <!-- COL -->
                  <div class="col-xl-3 col-lg-3  justify-center">
                          <div class="row">
                              <div class="col-lg-12 cols-12" style="font-size : 2.5rem ; font-weight: bold; text-align: center; color:#1bc5bd;">{{couponStat.available}}</div>
                          </div>
                          <div class="row">
                              <div class="col-lg-12 cols-12" style="font-size : 1rem ; color: #7d7d7d; text-align: center; color:#1bc5bd;">ว่างอยู่</div>
                          </div>
                  </div>

                  <!-- COL -->
                  <div class="col-xl-3 col-lg-3 ">
                    <div class="row">
                                  <div class="col-lg-12 cols-12" style="font-size : 2.5rem ; font-weight: bold; text-align: center; color:#ffa800;">{{couponStat.posted}}</div>
                              </div>
                              <div class="row">
                                  <div class="col-lg-12 cols-12" style="font-size : 1rem ; color: #7d7d7d; text-align: center; color:#ffa800;">ส่งออก</div>
                              </div>
                  </div>

                  <!-- COL -->
                  <div class="col-xl-3 col-lg-3 ">
                    <div class="row">
                                  <div class="col-lg-12 cols-12" style="font-size : 2.5rem ; font-weight: bold; text-align: center; color:#3f4254;">{{couponStat.used}}</div>
                              </div>
                              <div class="row">
                                  <div class="col-lg-12 cols-12" style="font-size : 1rem ; color: #7d7d7d; text-align: center; color:#3f4254;">ใช้แล้ว</div>
                              </div>
                  </div>
                  <!-- COL -->

                  <!-- COL -->
                  <div class="col-xl-3 col-lg-3 ">
                    <div class="row">
                                  <div class="col-lg-12 cols-12" style="font-size : 2.5rem ; font-weight: bold; text-align: center; color:#8950fc;">{{couponStat.canceled}}</div>
                              </div>
                              <div class="row">
                                  <div class="col-lg-12 cols-12" style="font-size : 1rem ; color: #7d7d7d; text-align: center; color:#8950fc;">ยกเลิก</div>
                              </div>
                  </div> <!-- COL -->
              
            </div> 
            <!-- ROW -->


  </div>

</template>

<script>

export default {
  name: "CouponStat",
  data() {
    return {  
   }
  },

  created() {
    // this.tableDetail.data = this.items;

  },
  computed: {
    // ...mapGetters(["items"])
    couponStat: {
      set(){

      },
      get(){
        return this.$store.getters["coupon/stat"];
      }
    }
  },

  components: {
    
  },
  methods: {
    // setCheck(checked) {
    //   this.checked = checked;
    // }
  }
};
</script>



<style lang="scss">
.topbar {
  .dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  .dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>